import { FC, memo, useEffect, useRef, useState } from 'react'

import { CheckMark } from '@syconium/little-miss-figgy'
import { intlPrettyCurrency } from '@syconium/magnolia/src/lib/utils'

import { useFixturesContext } from '../../../containers/fixtures'
import { LocalizationContainer } from '../../../containers/localization/LocalizationContainer'

import {
  CheckMarkCircleFade,
  CheckMarkFade,
  CheckMarkWrap,
  FreeShippingAchievedContainer,
  FreeShippingContainer,
  FreeShippingFadeInAndDown,
  FreeShippingGoal,
  FreeShippingText,
  FreeShippingTranslate,
  ProgressBar,
  SpendXMoreContainer,
} from './styles'

const FREE_SHIPPING_THRESHOLD: number = 50

const FreeShippingProgressBar: FC<{ progressAsPercent: number }> = ({ progressAsPercent }) => {
  return (
    <ProgressBar.Bar>
      <ProgressBar.Progress progressAsPercent={progressAsPercent} />
    </ProgressBar.Bar>
  )
}

function usePreviousCartTotal(value: number) {
  const ref = useRef<number>(value ?? 0)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const FreeShippingSection: FC<{ cartTotal: number }> = memo(
  ({ cartTotal }) => {
    const { freeShipping } = useFixturesContext()
    const { isUSRegion, locale, region } = LocalizationContainer.useContainer()

    // totalPrice is given in cents, so convert to dollars
    const cartPriceInDollars: number = cartTotal / 100
    const amountTilGoalReachedInCents: number = (FREE_SHIPPING_THRESHOLD - cartPriceInDollars) * 100
    const sanitizedAmountTilGoalReached: number =
      amountTilGoalReachedInCents >= 0 ? amountTilGoalReachedInCents : 0

    const [freeShippingThresholdMet, setFreeShippingThresholdMet] = useState<boolean>(
      cartPriceInDollars >= 50
    )
    const [progressBarFadeDirection, setProgressBarFadeDirection] = useState<'in' | 'out'>('out')
    const [enjoyFadeDirection, setEnjoyFadeDirection] = useState<'in' | 'out'>('in')
    const prevCartPrice = usePreviousCartTotal(cartPriceInDollars)

    useEffect(() => {
      if (cartPriceInDollars > prevCartPrice) {
        setEnjoyFadeDirection('in')
        setProgressBarFadeDirection('out')
      } else if (cartPriceInDollars < prevCartPrice) {
        setEnjoyFadeDirection('out')
        setProgressBarFadeDirection('in')
      }
    }, [cartPriceInDollars, prevCartPrice])

    useEffect(() => {
      setFreeShippingThresholdMet(cartPriceInDollars >= 50)
    }, [cartPriceInDollars])

    return (
      <>
        {isUSRegion && cartPriceInDollars > 0 ? (
          <FreeShippingContainer>
            {/*
             * "Enjoy Free Shipping!"
             *
             * Displayed when (and after) the FREE_SHIPPING_THRESHOLD has been met.
             * and only animated once, static otherwise unless the threshold
             * is gone below again, in which case the animation is run once again.
             */}
            <FreeShippingAchievedContainer
              id='FreeShippingAchieved'
              fadeDirection={enjoyFadeDirection}
              hide={!freeShippingThresholdMet}
            >
              <FreeShippingTranslate runAnimation={freeShippingThresholdMet}>
                <FreeShippingFadeInAndDown runAnimation={freeShippingThresholdMet}>
                  <CheckMarkCircleFade
                    id='CheckMarkCircle'
                    fadeDirection={'in'}
                    runAnimation={freeShippingThresholdMet}
                  >
                    <CheckMarkWrap>
                      <CheckMarkFade
                        id='CheckMarkFade'
                        fadeDirection={'in'}
                        runAnimation={freeShippingThresholdMet}
                      >
                        <CheckMark />
                      </CheckMarkFade>
                    </CheckMarkWrap>
                  </CheckMarkCircleFade>
                  <FreeShippingText asTag='p'>{freeShipping.enjoyFreeShipping}</FreeShippingText>
                </FreeShippingFadeInAndDown>
              </FreeShippingTranslate>
            </FreeShippingAchievedContainer>

            {/*
             * "Spend $X more for Free Shipping!" (with progress bar)
             *
             * Displayed until the FREE_SHIPPING_THRESHOLD is met, otherwise hidden.
             */}
            <SpendXMoreContainer
              id='SpendXMore'
              fadeDirection={progressBarFadeDirection}
              runAnimation={!freeShippingThresholdMet}
              hide={freeShippingThresholdMet}
            >
              <FreeShippingGoal>
                <FreeShippingText asTag='p'>
                  {intlPrettyCurrency({
                    cents: sanitizedAmountTilGoalReached,
                    region: region,
                    locale: locale,
                  })}{' '}
                  {freeShipping.moreForFreeShipping}
                </FreeShippingText>
              </FreeShippingGoal>
              <FreeShippingProgressBar
                progressAsPercent={(cartPriceInDollars / FREE_SHIPPING_THRESHOLD) * 100}
              />
            </SpendXMoreContainer>
          </FreeShippingContainer>
        ) : null}
      </>
    )
  },
  (prevProps, nextProps) => {
    if (prevProps.cartTotal === nextProps.cartTotal) return true
    else return false
  }
)
